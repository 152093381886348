import { cx } from "~/utils";
import { useSession } from "~/session";
import GenericGrid from "~/assets/pet-grid/generic.jpg";
import TwoDCartoonGrid from "~/assets/pet-grid/2d-cartoon.png";
import ThreeDCartoonGrid from "~/assets/pet-grid/3d-cartoon.png";
import ComicGrid from "~/assets/pet-grid/comic.png";
import FarWestGrid from "~/assets/pet-grid/far-west.png";
import FrenchGrid from "~/assets/pet-grid/french.png";
import GalacticGrid from "~/assets/pet-grid/galactic.png";
import PopArtGrid from "~/assets/pet-grid/popart.png";
import RoyalGrid from "~/assets/pet-grid/royal.png";
import SculptureGrid from "~/assets/pet-grid/sculpture.png";
import WizardGrid from "~/assets/pet-grid/wizard.png";

const gridImageMap = {
  generic: GenericGrid,
  twoDCartoon: TwoDCartoonGrid,
  threeDCartoon: ThreeDCartoonGrid,
  comic: ComicGrid,
  farWest: FarWestGrid,
  french: FrenchGrid,
  galactic: GalacticGrid,
  popart: PopArtGrid,
  royal: RoyalGrid,
  sculpture: SculptureGrid,
  wizard: WizardGrid,
} as const;

export const Grid = () => {
  const [session] = useSession();

  const gridImage = () => {
    // We only want to show custom grid images if the style selection
    // is disabled (eg. a style was preselected). This ensures that the
    // background doesnt change after each style change by the user.
    if (!session.skipPromptStyleSelection || !session.selectedPromptStyle) {
      return gridImageMap.generic;
    }

    return gridImageMap[session.selectedPromptStyle.prompt] ?? gridImageMap.generic;
  };

  return (
    <div class="relative size-full">
      <img class="absolute inset-0 size-full" src={gridImage().src} />
    </div>
  );
};

export const PetGrid = () => {
  // 0.25rem compensates for the gap between the grid items
  const transform = `translateX(calc(-75% - 0.25rem)) translateY(calc(-25% + 0.25rem))`;

  // render a 2x2 grid of the same images to ensure that there is no empty space during the intro animation
  return (
    <div
      style={{ transform }}
      class={cx([
        "pointer-events-none absolute left-1/2 top-1/2 grid grid-cols-2 gap-4",
        "h-[825vw] w-[825vw]",
        "md:h-[450vw] md:w-[450vw]",
        "lg:h-[275vw] lg:w-[275vw]",
        "xl:h-[210vw] xl:w-[210vw]",
      ])}
    >
      <Grid />
      <Grid />
      <Grid />
      <Grid />
    </div>
  );
};
